/* eslint no-console: 0 */

import Vue from 'vue/dist/vue.esm';
import VideoApp from '../videoplayer.vue';
import SearchApp from '../search.vue';
import YoutubeVue from 'youtube-vue';

Vue.component('videoplayer', VideoApp);
Vue.component('searchvids', SearchApp);
Vue.component('ytframe', YoutubeVue);

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '[data-behavior="vue"]',
  });
});
