<template>
  <div id="searchresults">
    <div id="thumbnails">
      <div id="thumbs">
        <div v-if="playlist.length > 0" class='well table-responsive'>
          <table class='table-condensed videothumbs'>
            <caption class='label label-primary'>Relevant Videos</caption>
            <tbody>
              <tr>
                <td v-for="item in playlist">
                  <img v-on:click="playItem(item.index)" :src="item.image" :title="item.title" :alt="item.description" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div id="playerdiv" title="LyricsIndia Video Player">
      <videoplayer ref="videoPlayer" v-show="showPlayer" v-bind:playlist="playlist" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data: function () {
    return {
      playlist: [],
      showPlayer: false
    };
  },
  methods: {
    playItem: function(index) {
      console.log("Will Play item: " + index + "( " + this.playlist[index].title + ")");
      this.showPlayer = true;
      this.$refs.videoPlayer.playTrack(index);
    }
  },
  mounted: function () {
    // console.log("Mounted Search component" );
    lyricsindia.searchapp = this;
    // Check the searchQuery and execute if required
    this.$nextTick(function () {
      var component = this;
      this.curatedVideos.forEach(function(v, idx, arr) {
        var obj = 
        {
          index: idx,
          title: 'Video',
          description: 'Video',
          mediaid: v,
          image: 'https://i.ytimg.com/vi/'+ v + '/hqdefault.jpg'
        };
        component.playlist.push(obj);
      });

    });
  },
  props: {
    searchQuery: {
      type: String,
      default: 'UNINIT'
    },
    curatedVideos: {
      type: Array
    }
  },
}
</script>

<style scoped>
</style>
